import React from "react"
import PropTypes from "prop-types"
import addToMailchimp from "gatsby-plugin-mailchimp"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
// import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from "@material-ui/core/styles"

const styles = theme => ({
  mailForm: {
    textAlign: "center",
    width: "100%",
  },
  textField: {
    width: "90%",
  },
})

class MailChimpForm extends React.Component {
  constructor() {
    super()
    this.state = {
      email: null,
      name: null,
      submitted: false,
    }
  }

  handleEmailChange = event => {
    this.setState({ email: event.target.value })
  }
  handleNameChange = event => {
    this.setState({ name: event.target.value })
  }
  // Since `addToMailchimp` returns a promise, you
  // can handle the response in two different ways:

  // Note that you need to send an email & optionally, listFields
  // these values can be pulled from React state, form fields,
  // or wherever.  (Personally, I recommend storing in state).

  // 1. via `.then`
  _handleSubmit = e => {
    e.preventDefault()
    addToMailchimp(this.state.email, {
      BUTTON: this.props.buttonType,
      OWNER: this.state.name,
    }) // listFields are optional if you are only capturing the email address.
      .then(data => {
        // I recommend setting data to React state
        // but you can do whatever you want (including ignoring this `then()` altogether)
        console.log(data)
        this.setState({
          submitted: true,
        })
      })
      .catch(() => {
        // unnecessary because Mailchimp only ever
        // returns a 200 status code
        // see below for how to handle errors
      })
  }

  render() {
    const { classes } = this.props
    const { email, name } = this.state
    return (
      <React.Fragment>
        {this.state.submitted ? (
          <Typography variant="h5" align="center" paragraph>
            Thank you for registering! We will be in touch soon.
          </Typography>
        ) : (
          <form className={classes.mailForm} onSubmit={this._handleSubmit}>
            <TextField
              id="outlined-name-input"
              className={classes.textField}
              label="Owner Name and Food truck"
              type="name"
              name="name"
              autoComplete="name"
              margin="normal"
              variant="outlined"
              onChange={this.handleNameChange}
            />
            <TextField
              id="outlined-email-input"
              className={classes.textField}
              label="Email"
              type="email"
              name="email"
              autoComplete="email"
              margin="normal"
              variant="outlined"
              onChange={this.handleEmailChange}
            />
            <br />
            <Button
              variant="contained"
              color="primary"
              label="Submit"
              type="submit"
              disabled={
                email === null || email === "" || name === null || name === ""
              }
            >
              Reserve your spot
            </Button>
          </form>
        )}
      </React.Fragment>
    )
  }
}

MailChimpForm.propTypes = {
  classes: PropTypes.object.isRequired,
  buttonType: PropTypes.string,
}

export default withStyles(styles)(MailChimpForm)
