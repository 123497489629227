import React from "react";
import PropTypes from 'prop-types';
import MailChimpForm from './form'
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import Dialog from '@material-ui/core/Dialog';

const styles = theme => ({
  mailForm: {
    textAlign: 'center',
  },
  emailInput :{
    backgroundColor: 'white',
    width: '300px',
  },
  form: {
    width: '100%'
  }
});

class PopoverForm extends React.Component {
  constructor() {
    super();
    this.state = {
      anchorEl: null,
      isOpen: false
    };
  }

  handleClick = event => {
    this.setState({
      anchorEl: event.currentTarget,
      isOpen: true
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
      isOpen: false
    });
  };

  render () {
    const { classes, buttonText, buttonType, buttonVariant } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <React.Fragment>
        <Button
          fullWidth
          aria-owns={open ? 'simple-popper' : undefined}
          aria-haspopup="true"
          variant={buttonVariant}
          onClick={this.handleClick}
          color="primary"
        >
          {buttonText}
        </Button>
        {/* <Popover
          id="simple-popper"
          open={open}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div>
            <MailChimpForm buttonType={buttonType}/>
          </div>
        </Popover> */}
      <Dialog
        fullWidth
        maxWidth={'sm'}
        open={this.state.isOpen}
        onClose={this.handleClose} aria-labelledby="Enter email" >
        <div className={classes.form}>
          <MailChimpForm buttonType={buttonType}/>
        </div>
      </Dialog>
      </React.Fragment>
    )
  }
}

PopoverForm.propTypes = {
  classes: PropTypes.object.isRequired,
  buttonText: PropTypes.string,
  buttonType: PropTypes.string,
  buttonVariant: PropTypes.string,
};

export default withStyles(styles)(PopoverForm);