import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import MailChimpForm from "../components/form"
import PopoverForm from "../components/popover"
import SEO from "../components/seo"

import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import Card from "@material-ui/core/Card"
import CardMedia from "@material-ui/core/CardMedia"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import CardHeader from "@material-ui/core/CardHeader"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import ListItemText from "@material-ui/core/ListItemText"
import Avatar from "@material-ui/core/Avatar"
import StarIcon from "@material-ui/icons/StarBorder"
import { makeStyles } from "@material-ui/core/styles"

import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline"
import Mood from "@material-ui/icons/Mood"

import foodTruck from "../images/food-rodeo.png" // Import using relative path
import groupPng from "../images/group.png"
import smartPhonePng from "../images/ecommerce.png"
import menuPng from "../images/menu.png"
import tabletPng from "../images/tablet.png"
import cookingPng from "../images/cooking.png"
import handPng from "../images/hand.png"

const useStyles = makeStyles(theme => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
    ul: {
      margin: 0,
      padding: 0,
    },
    li: {
      listStyle: "none",
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: "wrap",
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(5, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  foodTrucks: {
    backgroundImage: `url(${foodTruck})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right center",
    height: "400px",
  },
  benefitContent: {
    backgroundColor: "#ffeb3b",
    paddingTop: "1rem",
    paddingBottom: "2rem",
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
  },
  cardMedia: {
    paddingTop: "26.25%", // 16:9
    marginBottom: "1rem",
    marginTop: "1rem",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
  },
  cardGrid: {
    marginTop: "1rem",
  },
  card: {
    background: "#eeeeee",
    display: "flex",
  },
  cardContent: {
    background: "white",
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(2),
  },
  pricing: {
    backgroundColor: "white",
    paddingTop: "1rem",
    paddingBottom: "2rem",
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  logo: {
    maxWidth: "400px",
    margin: "auto",
    paddingTop: "6rem",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  form: {
    maxWidth: "400px",
    margin: "auto",
    width: "100%",
  },
  icons: {
    fill: "green",
    height: "42px",
    width: "42px",
  },
  listText: {
    fontSize: "1.5rem",
  },
}))

const tiers = [
  // {
  //   title: "Free",
  //   price: "0",
  //   description: [
  //     "Customizable menu",
  //     "Viewable by customers",
  //     "Update menu daily",
  //   ],
  //   buttonText: "Start a free trial",
  //   buttonVariant: "outlined",
  // },
  {
    title: "Pro",
    subheader: "Most popular",
    price: "TBD",
    description: [
      "Payment integration (Square)",
      "Instant menu updates",
      "Automatic order entries",
      "Save $ with annual purchase *",
    ],
    buttonText: "Get started",
    buttonVariant: "contained",
  },
  {
    title: "Premium",
    subheader: "Super user",
    price: "TBD",
    description: [
      "Same options as pro plan",
      "Analytics *",
      "Social media integration *",
      "Additional features",
    ],
    buttonText: "Sign up",
    buttonVariant: "outlined",
  },
]

const cards = [
  {
    icon: smartPhonePng,
    iconTitle: "Smartphone Icon",
    heading: "Contactless",
    description:
      "Better customer experience. Browse menu and order straight from their phone.",
  },
  {
    icon: cookingPng,
    iconTitle: "Cooking Icon",
    heading: "Optimize",
    description:
      "Free up your employee's time from taking orders. Focus on the food and get orders out faster.",
  },
  {
    icon: handPng,
    iconTitle: "Payment Icon",
    heading: "Easy to setup",
    description:
      "Integrates with your current payment system. Square only for beta release",
  },
  {
    icon: menuPng,
    iconTitle: "Menu Icon",
    heading: "Instant updates",
    description:
      "Let customers know when their order is ready. Update menu items on the fly.",
  },
]

function IndexPage() {
  const classes = useStyles()

  return (
    <React.Fragment>
      <SEO
        title="Street Eatz"
        keywords={[`Food truck ordering`, `Food truck menu`, `mobile food`]}
      />
      {/* Hero unit */}
      <div className={classes.foodTrucks}>
        <Container maxWidth="md">
          <div className={classes.logo}>
            <Image />
          </div>
        </Container>
      </div>
      <div className={classes.heroContent}>
        <Container maxWidth="md">
          <Grid container spacing={2} justify="center">
            <List dense={true}>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleOutline className={classes.icons} />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography
                      component="h4"
                      variant="h4"
                      className={classes.listText}
                    >
                      Contactless commerce
                    </Typography>
                  }
                  secondary={null}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleOutline className={classes.icons} />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography
                      component="h4"
                      variant="h4"
                      className={classes.listText}
                    >
                      Stop losing customers to long lines
                    </Typography>
                  }
                  secondary={null}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleOutline className={classes.icons} />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography className={classes.listText}>
                      Free up employee's time from taking orders
                    </Typography>
                  }
                  secondary={null}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleOutline className={classes.icons} />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography className={classes.listText}>
                      Customer convenience ordering on their phone
                    </Typography>
                  }
                  secondary={null}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Mood className={classes.icons} />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography className={classes.listText}>
                      Let Street Eatz handle the ordering process!
                    </Typography>
                  }
                  secondary={null}
                />
              </ListItem>
            </List>
          </Grid>
          <div className={classes.heroButtons}>
            <Grid container spacing={2} justify="center">
              <Grid className={classes.form} item>
                {/* <div className={classes.form}> */}
                <MailChimpForm buttonType={"main"} />
                {/* </div> */}
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
      <div className={classes.benefitContent}>
        <Container className={classes.cardGrid} maxWidth="md">
          {/* End hero unit */}
          <Typography
            component="h4"
            variant="h4"
            align="center"
            color="textPrimary"
            gutterBottom
          >
            Benefits
          </Typography>
          <Typography align="center" paragraph>
            By using Street Eatz you will improve customer service and
            convenience. Customers will be able to view the menu and order right
            from their smartphone. You can see the orders display through the
            app and focus on serving the customers. Free up your employee's time
            from taking orders.
          </Typography>
          <Grid container spacing={3}>
            {cards.map((card, i) => (
              <Grid item key={i} xs={12} sm={6} md={3}>
                <Card className={classes.card}>
                  <div className={classes.details}>
                    <CardMedia
                      className={classes.cardMedia}
                      image={card.icon}
                      title={card.iconTitle}
                    />
                    <CardContent className={classes.cardContent}>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="h2"
                        align="center"
                      >
                        {card.heading}
                      </Typography>
                      <Typography align="center">{card.description}</Typography>
                    </CardContent>
                  </div>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </div>
      <div className={classes.pricing}>
        <Container maxWidth="md" component="main">
          <Grid container spacing={5} alignItems="flex-end">
            {tiers.map((tier, i) => (
              // Enterprise card is full width at sm breakpoint
              <Grid
                item
                key={i + "tier"}
                xs={12}
                sm={tier.title === "Enterprise" ? 12 : 6}
                md={6}
              >
                <Card>
                  <CardHeader
                    title={tier.title}
                    subheader={tier.subheader}
                    titleTypographyProps={{ align: "center" }}
                    subheaderTypographyProps={{ align: "center" }}
                    action={tier.title === "Pro" ? <StarIcon /> : null}
                    className={classes.cardHeader}
                  />
                  <CardContent>
                    <div className={classes.cardPricing}>
                      <Typography
                        component="h2"
                        variant="h3"
                        color="textPrimary"
                      >
                        ${tier.price}
                      </Typography>
                      <Typography variant="h6" color="textSecondary">
                        /mo
                      </Typography>
                    </div>
                    <ul key={i}>
                      {tier.description.map((line, i) => (
                        <Typography
                          component="li"
                          variant="subtitle1"
                          align="center"
                          key={i}
                        >
                          {line}
                        </Typography>
                      ))}
                    </ul>
                  </CardContent>
                  <CardActions>
                    {/* <Button fullWidth variant={tier.buttonVariant} color="primary">
                        {tier.buttonText}
                      </Button> */}
                    <PopoverForm
                      buttonVariant={tier.buttonVariant}
                      buttonText={tier.buttonText}
                      buttonType={tier.title}
                    />
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default IndexPage
